/**
 * onboarding, main, wallet, friends, home
 */

export default [
    {
        path: 'wallet',
        name: 'wallet',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/Wallet.vue'),
    },
    {
        path: 'wallet/deposit/:address',
        name: 'deposit',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Input form',
        },
        component: () =>
            import('@/views/finbridge/pages/Deposit.vue'),
    },
]
