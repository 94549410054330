export default [
    {
        path: 'payment/:hash',
        name: 'payment',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Input form',
        },
        component: () => import('@/views/finbridge/pages/Acquiring/Payment.vue'),
    },
    {
        path: 'transactions',
        name: 'transactions',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/pages/Acquiring/Transactions.vue'),
    },
]
