import Wallet from '@/api/v2/wallet'

import showSwal from '@/libs/sweet-alerts.js'
import { clearUserInfo } from '@/libs/ton/utils'

import router from '@/router'
import { THEME, TonConnectUI } from '@tonconnect/ui'

/**
 * Инстанс
 */
export const tonConnectUI = new TonConnectUI({
    manifestUrl: 'https://durovs-protocol.com/tonconnect-manifest.json',
    uiPreferences: { theme: THEME.DARK },
})

/**
 * Отключение
 */
export async function disconnectWallet() {
    await tonConnectUI.disconnect()
    clearUserInfo()
    router.go()
}

/**
 * Подключение
 */
export async function connect() {
    try {
        const status = tonConnectUI.connected
        if (status) {
            await tonConnectUI.disconnect()
        }
        await tonConnectUI.openModal()

        const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
            if (wallet) {
                // await updateUserWallets(wallet)

                unsubscribe()

                await Wallet.connect(wallet.account.address).then(
                    async (res) => {console.log('Update wallet on: ' + wallet.account.address)}
                )
                router.go()
            } else {
                clearUserInfo()
            }
        })
    } catch (e) {
        console.log(e)
    }
}

/**
 *
 * Статус подключения
 */
export function isConnected() {
    if (localStorage.getItem('dev') === 'true') {
        return true
    }

    const connected = tonConnectUI.connected

    if (!connected) {
        clearUserInfo()
    }

    return connected
}

/**
 *
 * Получение подписи
 */
export function getSenderTonConnect() {
    return {
        sender: {
            send: async (args) => {
                // try {
                tonConnectUI
                    .sendTransaction({
                        messages: [
                            {
                                address: args.to.toString(),
                                amount: args.value.toString(),
                                payload: args.body?.toBoc().toString('base64'),
                            },
                        ],
                        validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
                    })
                    .then((res) => {
                        showSwal({
                            text: 'Request sent to the blockchain, pending',
                            icon: 'success',
                        })

                        if (!args.disableRedirect) {
                            router
                                .push({ name: args.redirectPage ?? 'home' })
                                .then(async (res) => {
                                    try {
                                        await Wallet.updateBalance()
                                    } catch (e) {
                                        console.log(e)
                                    }
                                })
                        }
                    })
                    .catch((err) => {
                        showSwal({
                            text: 'Sending error',
                            icon: 'error',
                        })
                        router.push({ name: 'home' })
                    })

                // } catch(e) {
                // console.log(e)
                // }
            },
        },
    }
}
