export default [
    {
        path: 'onboarding',
        name: 'onboarding',
        meta: {
            resource: 'CommonPages',
            layout: 'full',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/pages/Onboarding.vue'),
    },
    {
        path: 'security',
        name: 'security',
        meta: {
            resource: 'CommonPages',
            layout: 'full',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/pages/Security.vue'),
    },
    {
        path: 'academy',
        name: 'academy',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/pages/Academy.vue'),
    },
]
