import axiosInsV2 from '@/libs/axiosV2'

export default class Wallet {
    static async connect(address, balance) {
        return (
            await axiosInsV2.post(`/wallet/connect`, { address, balance })
        ).data
    }
    static async updateBalance() {
        return await axiosInsV2
            .post(`/wallet/update-balance`)
            .then((response) => {
              localStorage.expireDate = Date.now() + 5 * 60 * 1000
              return response.data
            })
            .catch((e) => console.log(e))
    }
}
