import home from "./home";
import wallet from "./wallet";

export default [
    ...home, 
    ...wallet,
    {
        path: 'invites',
        name: 'invites',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/Invites.vue'),
    },
    {
        path: 'tasks',
        name: 'tasks',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/Tasks.vue'),
    },
    {
        path: 'leaderboard',
        name: 'leaderboard',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/finbridge/Leaderboard.vue'),
    },
]
