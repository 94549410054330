export default [
        {
                path: '/',
                name: 'landing',
                component: () => import('@/views/finbridge/landing/home/Landing.vue'),
                meta: {
                        resource: 'CommonPages',
                        pageTitle: 'Landing',
                        layout: 'common',
                },
        },
        {
                path: 'about',
                name: 'about',
                meta: {
                        resource: 'CommonPages',
                        hide: true,
                        sitemap: {
                                ignoreRoute: true,
                        },
                        pageTitle: 'Your token',
                        layout: 'common',
                },
                component: () => import('@/views/finbridge/landing/about/AboutWrapper.vue'),
        },
        {
                path: 'contacts',
                name: 'contacts',
                meta: {
                        resource: 'CommonPages',
                        hide: true,
                        sitemap: {
                                ignoreRoute: true,
                        },
                        pageTitle: 'Your token',
                        layout: 'common',
                },
                component: () => import('@/views/finbridge/landing/contacts/ContactsWrapper.vue'),
        },
        {
                path: 'vacancies',
                name: 'vacancies',
                meta: {
                        resource: 'CommonPages',
                        hide: true,
                        sitemap: {
                                ignoreRoute: true,
                        },
                        pageTitle: 'Your token',
                        layout: 'common',
                },
                component: () => import('@/views/finbridge/landing/vacancies/VacanciesWrapper.vue'),

        },
        {
                path: 'vacancy/id=:id',
                name: 'vacancy',
                meta: {
                        resource: 'CommonPages',
                        hide: true,
                        sitemap: {
                                ignoreRoute: true,
                        },
                        pageTitle: 'Your token',
                        layout: 'common',
                },
                component: () => import('@/views/finbridge/landing/vacancies/Vacancy.vue'),
        },
        {
                path: 'faq',
                name: 'faq',
                meta: {
                        resource: 'CommonPages',
                        hide: true,
                        sitemap: {
                                ignoreRoute: true,
                        },
                        pageTitle: 'Your token',
                        layout: 'common',
                },
                component: () => import('@/views/finbridge/landing/faq/FaqWrapper.vue'),
        }
]