/**
 * onboarding, main, wallet, friends, home
 */

export default [
        {
                path: 'home',
                name: 'home',
                meta: {
                        resource: 'CommonPages',
                        layout: 'game',
                        hide: true,
                        sitemap: { ignoreRoute: true },
                        pageTitle: 'Runegame',
                },
                component: () => import('@/views/finbridge/Home.vue'),
        },
        {
                path: 'home/select-asset/:action',
                name: 'select-asset',
                meta: {
                        resource: 'CommonPages',
                        layout: 'game',
                        hide: true,
                        sitemap: { ignoreRoute: true },
                        pageTitle: 'Select asset',
                },
                component: () => import('@/views/finbridge/components/actions/AssetsList.vue'),
        },
        {
                path: 'home/select-action/:asset',
                name: 'select-action',
                meta: {
                        resource: 'CommonPages',
                        layout: 'game',
                        hide: true,
                        sitemap: { ignoreRoute: true },
                        pageTitle: 'Select action',
                },
                component: () => import('@/views/finbridge/components/actions/ActionsList.vue'),
        },
        {
                path: 'home/input/:action/:asset',
                name: 'action-input',
                meta: {
                        resource: 'CommonPages',
                        layout: 'game',
                        hide: true,
                        sitemap: { ignoreRoute: true },
                        pageTitle: 'Input form',
                },
                component: () => import('@/views/finbridge/pages/Send.vue'),
        },
]